// third-party
import { createSlice } from '@reduxjs/toolkit'

// project-imports

// types
import { ProjectTypes } from 'types/projects'

const initialState: ProjectTypes = {
	error: false,
	projects: [],
	genres: [
		'Comics',
		'Black Humor',
		'Lame Heroes',
		'Bribery',
		'Action',
		'Fantasy',
		'Mystery',
		'Horror',
		'Superheroes',
		'Drama',
		'Slice of Life',
		'Life Romance',
		'Superhero',
		'Sci-fi',
		'Supernatural',
		'Sports',
		'Historical',
		'Heartwarming',
		'Informative',
		'Nuance',
		'Occultism',
		'Hero',
		'Manga',
		'Children',
		'Comedy',
		'Shonen',
		'Shojo',
		'Seinen',
		'Josei',
		'Kodomo',
		'Isekai',
		'Mecha',
		'Harem',
		'Reverse Harem',
		'Yaoi',
		'Yuri',
		'Crime & Mystery',
		'War & Military',
		'Post-Apocalyptic',
		'Mythological & Folklore',
		'Satire',
		'Political & Social Commentary',
		'Suspense',
		'Western',
		'Indonesian',
		'Korean',
		'Historical Fiction',
	],
	isAdmin: false,
	isCouponCreator: false,
	isUserSuperAdmin: false,
	currentProject: {},
	statuses: ['PUBLISHED', 'DRAFT', 'UPCOMING'],
	ages: ['For All', '16+', '18+'],
	projectTypes: [
		'Comics',
		'Vertical',
		'Manga',
		'Manhwa',
		'Manhua',
		'Western Comics',
		'Indonesian Comics',
	],
	updateTime: [
		'Completed',
		'Ongoing(weekly)',
		'Ongoing(monthly)',
		'On hold',
		'No translator',
		'Announcement',
		'Licensed',
		'Finished',
	],
}

// ==============================|| SLICE - CHAT ||============================== //

const profileProjects = createSlice({
	name: 'users',
	initialState,
	reducers: {
		// HAS ERROR
		hasError(state, action) {
			state.error = action.payload
		},

		getProjects(state, action) {
			state.projects = action.payload
		},
		getCurrentProject(state, action) {
			state.currentProject = action.payload
		},
		isUserAdmin(state, action) {
			state.isAdmin = action.payload
		},
		isCouponCreator(state, action) {
			state.isCouponCreator = action.payload
		},
		isUserSuperAdmin(state, action) {
			state.isUserSuperAdmin = action.payload
		},
	},
})

export default profileProjects.reducer
export const {
	getProjects,
	isUserAdmin,
	isUserSuperAdmin,
	isCouponCreator,
	getCurrentProject,
} = profileProjects.actions
